import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import styles from "../components/pageTitle.module.css";

import Layout from "../components/layout";
import MainBg from "../components/mainBg";
import NewsletterSignup from "../components/newsletterSignup";
import CallToActionGeneric from "../components/callToActionGeneric";

class Storm extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Louisiana Hurricane & Flooding Resources | Lewis Mohr"
          meta={[
            {
              name: "description",
              content:
                "As a Baton Rouge based business, choosing a locally-owned, independent insurance agency, like Lewis Mohr Insurance Agency, will give you piece of mind knowing you have a trusted partner in your corner.  Contact us for a quote!",
            },
          ]}
        />
        <MainBg
          mainBg={this.props.data.mainBg}
          alt="Louisiana storm, hurricane, flood"
        />
        <div className="primary-bg">
          <div className="grid grid-justifyCenter">
            <div className="grid-cell ie-fix callout">
              <div>
                <h1>Louisiana Hurricane & Flooding Resources</h1>
                <div className={styles.separator}></div>
              </div>
            </div>
          </div>
        </div>
        <div className="site-body page-content storm-page">
          <h2>Before a Storm Resources</h2>
          <div className="services-title-seperator"></div>
          <h4>General Resources</h4>
          <p>
            <ul>
              <li>
                Our Guide to Insurance Coverage for Hurricanes:{" "}
                <a
                  href="/posts/a-guide-to-insurance-coverage-for-hurricanes/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read Our Guide
                </a>
              </li>
              <li>
                How to Prepare Your Home and Family For Hurricane Season:{" "}
                <a
                  href="/posts/how-to-prepare-your-home-and-family-for-hurricane-season/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read Our Guide
                </a>
              </li>
              <li>
                Preparation and creating an emergency plan:{" "}
                <a
                  href="https://getagameplan.org"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  getagameplan.org
                </a>
              </li>
              <li>
                Additional Disaster Preparedness Information:{" "}
                <a
                  href="https://www.redcross.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  redcross.org
                </a>
              </li>
            </ul>
          </p>
          <h4>Flooding Resources</h4>
          <p>
            <ul>
              <li>
                Our Guide to Flood Insurance for Your Home & Family:{" "}
                <a
                  href="/posts/flood-insurance-protecting-your-home-and-your-family/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read Our Guide
                </a>
              </li>
              <li>
                Our Guide to Flood Insurance for Your Business:{" "}
                <a
                  href="/posts/a-guide-to-flood-insurance-for-your-business/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read Our Guide
                </a>
              </li>
              <li>
                Your Community’s Flood Map:{" "}
                <a
                  href="https://msc.fema.gov/portal/home"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  fema.gov/portal
                </a>
              </li>
            </ul>
          </p>
          <hr />
          <h2>During a Storm Resources</h2>
          <div className="services-title-seperator"></div>
          <p>
            <ul>
              <li>
                What to Do When A Hurricane Watch or Warning Is Issued:{" "}
                <a
                  href="/posts/what-to-do-when-a-hurricane-watch-or-warning-is-issued/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read Our Guide
                </a>
              </li>
              <li>
                Storm Path Updates:{" "}
                <a
                  href="https://www.weather.gov"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  weather.gov
                </a>
              </li>
              <li>
                Updates From the Governor's Office:{" "}
                <a
                  href="https://gohsep.la.gov/emergency/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  gohsep.la.gov/emergency
                </a>
              </li>
            </ul>
          </p>
          <hr />
          <h2>After a Storm Resources</h2>
          <div className="services-title-seperator"></div>
          <h4>General Resources</h4>
          <p>
            <ul>
              <li>
                <a
                  href="https://assets.ctfassets.net/au2kkaj781oc/2EesATKFHe0MG7MVGBYJ92/8c4151326d594041572c5184dcc4929a/Insurance_Company_Claims_Filing_Telephone_Numbers.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Insurance Companies Claims Phone Numbers
                </a>
              </li>
              <li>
                <a
                  href="http://files.esfi.org/file/ESFI-Downed-Power-Lines.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Downed Power Line Safety
                </a>
              </li>
            </ul>
          </p>
          <h4>Flooding Resources</h4>
          <p>
            <ul>
              <li>
                <a
                  href="https://www.fema.gov/sites/default/files/2020-05/FINAL_ClaimsHandbook_10252017.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  NFIP Flood Insurance Claims Handbook
                </a>
              </li>
              <li>
                <a
                  href="https://www.floodsmart.gov/start"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  General Info from the National Flood Insurance Program
                </a>
              </li>
              <li>
                <a
                  href="https://www.floodsmart.gov/flood/document-damage"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  How to Document Damage After a Flood
                </a>
              </li>
              <li>
                <a
                  href="https://www.fema.gov/sites/default/files/2020-07/fema_nfip_how-to-file-flood-insurance-claim.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  How to File a Flood Insurance Claim
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/watch?v=nTU-zUirEy8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Meeting with a Flood Insurance Adjuster
                </a>
              </li>
              <li>
                <a
                  href="https://www.epa.gov/sites/default/files/2017-08/documents/mold._homeowners_and_renters_guide_to_cleanup_after_disasters.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Guide to Mold Cleanup After Disasters
                </a>
              </li>
            </ul>
          </p>
        </div>
        <CallToActionGeneric bg={this.props.data.callToActionBg} />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default Storm;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "storm-bg.jpg" }) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-28.jpg" }) {
      ...mainBg
    }
  }
`;
